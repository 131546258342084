<template>
  <label for="first-name"> {{ t("employee.first-name") }} </label>
  <InputText
    id="first-name"
    ref="inputRef"
    type="text"
    v-model="firstNameComputed"
    data-testid="first-name"
    class="inputfield w-full"
    :class="{ 'p-invalid': val.firstName.$error }"
    :placeholder="t('placeholder.select', { property: t('employee.first-name').toLowerCase() })"
    maxlength="8000"
  />
  <small class="p-error" v-if="val.firstName.$error" data-testid="first-name-error">{{
    val.firstName.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { computed, ref } from "vue";

const { t } = useI18n();

const props = defineProps<{
  firstName: string;
}>();

const emit = defineEmits<{
  (e: "update:firstName", value: string): void;
}>();

const firstNameComputed = computed<string>({
  get: () => {
    return props.firstName ?? "";
  },
  set: (value) => {
    emit("update:firstName", value);
  },
});

const rules = {
  firstName: {
    required,
  },
};

const val = useValidate(rules, props);

const inputRef = ref();
const focus = () => {
  inputRef.value.$el.focus();
};

defineExpose({
  focus,
});
</script>
