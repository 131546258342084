<template>
  <label for="client-status">
    {{ t("client.client-status") }}
  </label>
  <Dropdown
    id="client-status"
    v-model="clientStateComputed"
    :options="options"
    optionLabel="label"
    optionValue="value"
    class="w-full"
    data-testid="client-status"
    @show="isActive = true"
    @hide="isActive = false"
    @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
  />
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { ClientState } from "@/repositories/client/model/ClientState";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  clientState: ClientState;
}>();

const emit = defineEmits<{
  (e: "update:clientState", value: string): void;
}>();

const isActive = ref(false);

const options = ref<Record<string, string>[]>([]);

const fetchClientStates = () => {
  for (const state in ClientState) {
    options.value.push({
      label: t(`client.status.${state.toString().toLowerCase()}`),
      value: state,
    });
  }
};

onMounted(fetchClientStates);

const clientStateComputed = computed<ClientState>({
  get: () => {
    return props.clientState ?? ClientState.Active;
  },
  set: (value) => {
    emit("update:clientState", value);
  },
});
</script>
