<template>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <PrimeButton
          class="c-circular-button mr-3"
          data-testid="add-client-btn"
          @click="router.push({ name: 'client-add' })"
        >
          <i class="pi pi-plus c-success-button c-circular-icon"></i>
          <span class="px-3">{{ t("common.add") }}</span>
        </PrimeButton>
        <FileUpload
          mode="basic"
          accept=".csv"
          :maxFileSize="100000"
          label="Import"
          chooseLabel="Import"
          class="c-circular-button mr-3 pr-3"
          :disabled="true"
        ></FileUpload>
        <PrimeButton class="c-circular-button mr-3" @click="$emit('onExport')">
          <i class="pi pi-upload c-default-button c-circular-icon"></i>
          <span class="px-3">{{ t("client.export") }}</span>
        </PrimeButton>
        <PrimeButton class="c-circular-button" @click="emit('clientsListsRefresh')" data-testid="refresh-button">
          <i class="pi pi-refresh c-default-button c-circular-icon"></i>
          <span class="px-3">{{ t("common.reload") }}</span>
        </PrimeButton>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const router = useRouter();

const { t } = useI18n();

const emit = defineEmits<{
  (e: "onExport"): void;
  (e: "clientsListsRefresh"): void;
}>();
</script>
<style scoped lang="scss">
:deep(.p-button-icon, .pi-upload) {
  background-color: var(--default-btn-bg);
  padding: 0.3rem;
  border-radius: 50%;
  color: var(--primary-color-text);
}
</style>
