<template>
  <label for="roles" class="c-under-construction"></label>
  <MultiSelect
    v-model="rolesSelected"
    :options="roleOptions"
    optionLabel="name"
    optionValue="id"
    :placeholder="t('placeholder.select', { property: t('employee.roles').toLowerCase() })"
    data-testid="roles"
    class="inputfield w-full"
    @show="isActive = true"
    @hide="isActive = false"
    @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
  />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { Role } from "@/repositories/employee/role/model/Role";

const { t } = useI18n();

const isActive = ref(false);

const props = defineProps<{ roles: string[]; roleOptions: Role[] }>();
const emit = defineEmits<{ (e: "update:roles", value: string[]): void }>();

const rolesSelected = computed<string[]>({
  get: () => props.roles,
  set: (roles) => emit("update:roles", roles),
});
</script>
