<template>
  <FloatLabelDropdown
    id="datatable-style"
    dataTestId="datatable-style"
    v-model:value="dataTabelStyle"
    :options="dropdownOptions"
    :label="t(`employee.datatable-style`)"
    :placeholder="t('placeholder.select', { property: t('employee.datatable-style').toLowerCase() })"
    :isFloatingLabel="isFloatingLabel"
  />
</template>

<script setup lang="ts">
import { DataTableStyle } from "@cumulus/event-bus";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const dataTabelStyle = defineModel<string>("dataTabelStyle");

const { t } = useI18n();

const dropdownOptions = computed(() => {
  const keys = Object.keys(DataTableStyle);
  return keys.map((key) => {
    return { name: t(`employee.datatable-style-type.${key.toLowerCase()}`), value: key };
  });
});

const isFloatingLabel = computed(() => {
  if (dataTabelStyle.value === undefined) {
    return false;
  }

  return dataTabelStyle.value !== "";
});
</script>
