<template>
  <CumulusDatePicker
    v-model:date="deactivationDateComputed"
    :id="'deactivation-date'"
    :dataTestId="'deactivation-date'"
    :label="t('employee.deactivation-date')"
  />
  <small class="p-error" v-if="val.deactivationDate.$error" data-testid="deactivation-date-error">{{
    val.deactivationDate.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import useValidate from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { useI18n } from "vue-i18n";
import { CumulusDatePicker } from "@cumulus/components";
import { computed } from "vue";

const props = defineProps<{
  deactivationDate: string;
}>();

const emit = defineEmits<{
  (e: "update:deactivationDate", value: string): void;
}>();

const { t } = useI18n();

const deactivationDateComputed = computed({
  get: () => props.deactivationDate,
  set: (value) => {
    emit("update:deactivationDate", value);
  },
});

const rules = {
  deactivationDate: {
    required,
  },
};

const val = useValidate(rules, props);
</script>
