<template>
  <label for="default-client" class="c-under-construction">{{ t("employee.default-client") }}</label>
  <Dropdown
    id="default-client"
    class="inputfield w-full mt-1"
    :class="{ 'p-invalid': val.defaultClientId.$error }"
    v-model="defaultClient"
    :options="clientOptions"
    optionLabel="name"
    optionValue="id"
    data-testid="default-client"
    :placeholder="t('placeholder.select', { property: t('employee.default-client').toLowerCase() })"
    @show="isActive = true"
    @hide="isActive = false"
    @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
  />
  <small class="p-error" data-testid="default-client-error" v-if="val.defaultClientId.$error">{{
    val.defaultClientId.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/locales/i18n-validators";
import { Client } from "@/repositories/employee/client/model/Client";
import useValidate from "@vuelidate/core";

const { t } = useI18n();

const props = defineProps<{ defaultClientId: string; clientOptions: Client[] }>();
const emit = defineEmits<{ (e: "update:defaultClientId", value: string): void }>();

const isActive = ref(false);

const defaultClient = computed<string>({
  get: () => props.defaultClientId,
  set: (client) => emit("update:defaultClientId", client),
});

const val = useValidate({ defaultClientId: { required } }, props);
</script>
