<template>
  <CompanyHeader @onClose="routeToDashboard" />
  <div class="c-company">
    <div class="c-card">
      <div class="grid">
        <div class="col-12 lg:col-6">
          <div class="formgrid grid">
            <div class="field col-12 lg:col-6">
              <CompanyName v-model:companyName="company.companyName" :disabled="true" />
            </div>
            <div class="field col-12 lg:col-6">
              <OrganizationNumber v-model:organizationNumber="company.organizationNumber" :disabled="true" />
            </div>
            <div class="field col-12 lg:col-6">
              <Email v-model:email="company.email" :disabled="true" />
            </div>
            <div class="field col-12 lg:col-6">
              <CumulusDatePicker
                v-model:date="company.registrationDate"
                :disabled="true"
                :label="t(`company.registration-date`)"
                :dataTestId="'company-registration-date'"
              />
            </div>
            <div class="field col-12 lg:col-6">
              <TelephoneNumber v-model:telephoneNumber="company.telephoneNumber" :disabled="true" />
            </div>
          </div>
        </div>

        <div class="col-12 lg:col-6">
          <div class="formgrid grid c-company-address">
            <Address
              :address="company.companyAddress"
              @setAddress="setCompanyAddress"
              :countryOptions="countryOptions"
              address-type="company"
              :disabled="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { ShortcutAction, useShortcut } from "@cumulus/shortcut";
import { CumulusDatePicker } from "@cumulus/components";
import { Company } from "@/repositories/company/model/Company";

import { Address as AddressModel } from "@/repositories/company/model/Address";
import { Country } from "@/repositories/country/model/Country";

import CompanyName from "./components/CompanyName.vue";
import OrganizationNumber from "./components/OrganizationNumber.vue";
import Email from "./components/Email.vue";
import TelephoneNumber from "./components/TelephoneNumber.vue";
import Address from "./components/Address.vue";
import CompanyHeader from "./components/CompanyHeader.vue";
import cloneDeep from "lodash.clonedeep";
import { useCountry } from "@/repositories/country/CountryService";
import { useCompany } from "@/repositories/company/CompanyService";

const router = useRouter();
const { t } = useI18n();
const company = ref<Company>({
  id: "",
  companyName: "",
  email: "",
  registrationDate: "",
  organizationNumber: "",
  active: false,
  telephoneNumber: "",
  companyAddress: {
    addressLines: [""],
    postalCode: "",
    city: "",
    countryIso: "",
  },
});

const { getCompany } = useCompany();

const loadCompany = async () => {
  company.value = await getCompany();
};

onMounted(loadCompany);

const routeToDashboard = () => {
  router.push("/");
};

const { getCountries } = useCountry();

const countryOptions = ref<Country[]>([]);
const fetchCountryData = async () => {
  countryOptions.value = await getCountries();
};

onMounted(fetchCountryData);

const setCompanyAddress = (value: AddressModel) => {
  const address = cloneDeep(value);
  company.value.companyAddress = address;
};
useShortcut(ShortcutAction.cancel, routeToDashboard);
</script>

<style lang="scss">
.c-company {
  margin: var(--default-content-margin);
}

.p-datepicker-trigger {
  display: none;
}

.c-company-address {
  @media screen and (min-width: 992px) {
    margin-left: 0;
    padding-left: 0.5rem;
    border-left: var(--footer-border);
  }
}
</style>
