<template>
  <label for="default-warehouse">{{ t("employee.default-warehouse") }}</label>
  <Dropdown
    id="default-warehouse"
    class="inputfield w-full mt-1"
    :class="{ 'p-invalid': val.defaultWarehouseId.$error }"
    v-model="defaultWarehouse"
    :options="warehouseOptions"
    optionLabel="name"
    optionValue="id"
    data-testid="default-warehouse"
    :placeholder="t('placeholder.select', { property: t('employee.default-warehouse').toLowerCase() })"
    @show="isActive = true"
    @hide="isActive = false"
    @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
  />
  <small data-testid="warehouses-error" class="p-error" v-if="val.defaultWarehouseId.$error">{{
    val.defaultWarehouseId.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/locales/i18n-validators";
import { Warehouse } from "@/repositories/employee/model/Warehouse";
import useValidate from "@vuelidate/core";

const { t } = useI18n();

const props = defineProps<{ defaultWarehouseId: string; warehouseOptions: Warehouse[] }>();
const emit = defineEmits<{ (e: "update:defaultWarehouseId", value: string): void }>();

const isActive = ref(false);

const defaultWarehouse = computed<string>({
  get: () => props.defaultWarehouseId,
  set: (warehouseId) => emit("update:defaultWarehouseId", warehouseId),
});

const val = useValidate({ defaultWarehouseId: { required } }, props);
</script>
