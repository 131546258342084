<template>
  <div class="c-card c-setting-card">
    <div class="grid mt-2">
      <div class="col-12 md:col-6 lg:col-4">
        <div class="formgrid grid">
          <div class="col-12 w-full">
            <div class="font-medium mb-2">{{ t("settings.freight.header") }}</div>

            <div>
              <PrimeButton
                :label="t('settings.freight.freight-method')"
                class="p-button-text pl-0 w-full text-left text-sm"
                @click="router.push(`/freight/methods`)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 md:col-6 lg:col-4">
        <div class="formgrid grid">
          <div class="col-12 w-full">
            <div class="font-medium mb-2">{{ t("settings.warehouse.header") }}</div>

            <div>
              <PrimeButton
                :label="t('settings.warehouse.warehouse-items')"
                class="p-button-text pl-0 w-full text-left text-sm"
                @click="router.push(`/warehouse/configurations`)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 md:col-6 lg:col-4">
        <div class="formgrid grid">
          <div class="col-12 w-full">
            <div class="font-medium mb-2">{{ t("settings.attribute.header") }}</div>

            <div>
              <PrimeButton
                :label="t('settings.attribute.attributes')"
                class="p-button-text pl-0 w-full text-left text-sm"
                @click="router.push(`/product/attributes`)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 md:col-6 lg:col-4">
        <div class="formgrid grid">
          <div class="col-12 w-full">
            <div class="font-medium mb-2">{{ t("settings.import.header") }}</div>
            <div>
              <PrimeButton
                :label="t('settings.import.imports')"
                class="p-button-text pl-0 w-full text-left text-sm"
                @click="router.push(`/system-data/imports`)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
const router = useRouter();

const { t } = useI18n();
</script>
