<template>
  <PrimeDialog
    v-model:visible="visible"
    :header="t('printing.set-default-printer')"
    :modal="true"
    id="set-default-printer-dialog"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
    class="w-4"
  >
    <div class="formgrid grid">
      <div class="field col-12">
        <label for="printer">{{ t("printing.printer") }}</label>
        <Dropdown
          id="printer"
          v-model="defaultPrinterRef.printerName"
          :options="printers"
          class="w-full"
          optionLabel="name"
          optionValue="name"
          data-testid="default-printer-dropdown"
          :placeholder="t('placeholder.select', { property: t('printing.printer').toLowerCase() })"
        />
        <small class="p-error" v-if="val.printerName.$error" data-testid="printing-name-error">
          {{ val.printerName.$errors[0].$message }}
        </small>
      </div>

      <div class="field col-12">
        <label for="printer">{{ t("printing.document-type.label") }}</label>
        <span class="block w-full">{{
          t(`printing.document-type.${defaultPrinterInfo.documentType.toLowerCase()}`)
        }}</span>
      </div>
      <div class="field col-12">
        <label for="printer">{{ t("printing.location") }}</label>
        <InputText
          id="location"
          v-model="defaultPrinterRef.location"
          data-testid="default-printer-location-input"
          class="inputfield w-full"
        />
        <small class="p-error" v-if="val.location.$error" data-testid="printing-location-error">
          {{ val.location.$errors[0].$message }}
        </small>
      </div>
      <div class="field col-12">
        <label for="printer">{{ t("printing.description") }}</label>
        <InputText
          id="location"
          v-model="defaultPrinterRef.description"
          data-testid="default-printer-description-input"
          class="inputfield w-full"
        />
        <small class="p-error" v-if="val.location.$error" data-testid="printing-description-error">
          {{ val.location.$errors[0].$message }}
        </small>
      </div>
    </div>
    <div class="flex justify-content-end">
      <PrimeButton @click="onDeleteDefaultPrinter" data-testid="btn-delete" class="c-circular-button mr-3">
        <i class="pi pi-trash c-delete-button c-circular-icon" />
        <span class="px-3">{{ t("common.delete") }}</span>
      </PrimeButton>
      <PrimeButton @click="visible = false" data-testid="btn-cancel" class="c-circular-button mr-3">
        <i class="pi pi-times c-warning-button c-circular-icon"></i>
        <span class="px-3">{{ t("common.cancel") }}</span>
      </PrimeButton>
      <PrimeButton
        @click="saveDefaultPrinter"
        data-testid="btn-save-default-printer"
        class="c-circular-button mr-3"
        :disabled="isSaving"
      >
        <ProgressSpinner class="w-2rem h-2rem" v-if="isSaving" />
        <i v-else class="pi pi-check c-success-button c-circular-icon"></i>
        <span class="px-3">{{ t("common.save") }}</span>
      </PrimeButton>
    </div>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { Printer } from "../models/Printer";
import { DefaultPrinterInfo } from "../models/DefaultPrinterInfo";
import { useDocumentService } from "../api/DocumentService";
import { useCumulusToast } from "@cumulus/toast";
import { useToast } from "primevue/usetoast";
import cloneDeep from "lodash.clonedeep";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";

const { t } = useI18n();
const toast = useCumulusToast(useToast());
const { setDefaultPrinter, deleteDefaultPrinter } = useDocumentService();

const props = defineProps<{
  visibleDialog: boolean;
  defaultPrinterInfo: DefaultPrinterInfo;
  printers: Printer[];
}>();

const emit = defineEmits<{
  (e: "update:visibleDialog", value: boolean): void;
}>();

const visible = computed<boolean>({
  get: () => props.visibleDialog,
  set: (value) => emit("update:visibleDialog", value),
});

const defaultPrinterRef = ref<DefaultPrinterInfo>(cloneDeep(props.defaultPrinterInfo));

const isSaving = ref<boolean>(false);

const onDeleteDefaultPrinter = async () => {
  try {
    isSaving.value = true;
    await deleteDefaultPrinter(defaultPrinterRef.value.documentType);

    toast.add({
      severity: "success",
      summary: t("printing.default-printer-deleted"),
    });

    emit("update:visibleDialog", false);
  } finally {
    isSaving.value = false;
  }
};

const saveDefaultPrinter = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
    });
    return;
  }

  try {
    isSaving.value = true;
    await setDefaultPrinter(defaultPrinterRef.value);

    toast.add({
      severity: "success",
      summary: t("printing.default-printer-saved"),
    });

    emit("update:visibleDialog", false);
  } finally {
    isSaving.value = false;
  }
};

const rules = {
  printerName: {
    required,
  },
  location: {
    required,
  },
  description: {
    required,
  },
};

const val = useVuelidate(rules, defaultPrinterRef);
</script>
