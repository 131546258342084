<template>
  <BeforeUnloadBrowserTab :hasUpdates="hasUpdates" />
  <EmployeeHeader
    :editMode="true"
    :hasUpdates="hasUpdates"
    :unsavedChangesDialogVisible="unsavedChangesDialogVisible"
    :isSaving="isSaving"
    @stayOnPage="stayOnPage"
    @onDelete="onDeleteEmployee"
    @onCommit="onSave"
    @onCancel="onCancel"
    @routeToEmployeeSearch="routeToEmployeeSearch"
  />
  <div v-if="loadFailed">
    <div class="c-card">
      {{ t("common.error-load", { entity: t("employee.employee").toLowerCase(), errorReason: errorReason }) }}
    </div>
  </div>
  <div v-if="!loadFailed && !isLoading" class="c-edit-employee">
    <div class="mt-2">
      <div class="grid">
        <div class="flex col-12">
          <div class="c-card w-full">
            <div class="formgrid grid">
              <div class="field col-12 md:col-3">
                <FirstName v-model:firstName="employee.firstName" ref="employeeFirstnameRef" />
              </div>
              <div class="field col-12 md:col-4">
                <LastName v-model:lastName="employee.lastName" />
              </div>
              <div class="field col-12 md:col-3">
                <Initials v-model:initials="employee.initials" />
              </div>
              <div class="field col-12 md:col-2">
                <Language v-model:languageCode="employee.languageCode" />
              </div>
              <div class="field col-12 md:col-7">
                <Email v-model:email="employee.email" />
              </div>
              <div class="field col-12 md:col-3">
                <PhoneNumber v-model:phoneNumber="employee.phoneNumber" />
              </div>
              <div class="field col-12 md:col-2">
                <EmployeeState v-model:employeeState="employee.employeeState" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="grid">
        <div class="flex col-12">
          <CumulusPanel
            toggleTestId="c-access-and-prefrences-panel"
            :collapsed="panels.get('accessAndPrefrencesPanel')"
            @onCollapsedChange="panels.set('accessAndPrefrencesPanel', $event)"
          >
            <template #title> {{ t("employee.panels.access-and-prefrence") }} </template>
            <template #content>
              <div class="grid">
                <div class="feild col-12 lg:col c-border-div p-4">
                  <div class="text-center mt-2">
                    <label for="valid-date">{{ t("common.valid-date") }}</label>
                  </div>

                  <StartDate v-model:startDate="employee.startDate" />

                  <DeactivationDate v-model:deactivationDate="employee.deactivationDate" />
                </div>
                <div class="col-12 lg:col c-border-div p-4">
                  <div class="text-center">
                    <label for="valid-date">{{ t("employee.roles") }}</label>
                  </div>
                  <Roles v-model:roles="employee.roles" :roleOptions="roleOptions" />
                </div>
                <div class="col-12 lg:col c-border-div p-4">
                  <div class="text-center">
                    <label for="valid-date">{{ t("employee.clients") }}</label>
                  </div>

                  <Clients v-model:clients="employee.clients" :clientOptions="clientOptions" />

                  <DefaultClient
                    v-model:defaultClientId="employee.defaultClientId"
                    :clientOptions="clientOptions.filter((client) => employee.clients.includes(client.id))"
                  />
                </div>
                <div class="col-12 lg:col p-4">
                  <div class="text-center">
                    <label for="valid-date">{{ t("employee.warehouse") }}</label>
                  </div>
                  <div class="p-2">
                    <DefualtWarehouse
                      v-model:defaultWarehouseId="employee.warehouseId"
                      :warehouseOptions="warehouseOptions"
                    />

                    <div class="mt-4 mb-4 -mx-4">
                      <DataTableLayout v-model:dataTabelStyle="dataTableStyleComputed" />
                    </div>
                  </div>
                </div>
              </div> </template
          ></CumulusPanel>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import useValidate from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { ShortcutAction, useShortcut } from "@cumulus/shortcut";
import { CumulusPanel } from "@cumulus/panel";
import { BeforeUnloadBrowserTab } from "@cumulus/components";
import isEqual from "lodash.isequal";
import cloneDeep from "lodash.clonedeep";

import { useEmployee } from "@/repositories/employee/EmployeeService";
import { Employee } from "@/repositories/employee/model/Employee";
import { Client } from "@/repositories/employee/client/model/Client";
import { Role } from "@/repositories/employee/role/model/Role";
import { Warehouse } from "@/repositories/employee/model/Warehouse";

import FirstName from "../components/FirstName.vue";
import LastName from "../components/LastName.vue";
import PhoneNumber from "../components/PhoneNumber.vue";
import Initials from "../components/Initials.vue";
import Email from "../components/Email.vue";
import Clients from "../components/Clients.vue";
import DefaultClient from "../components/DefaultClient.vue";
import EmployeeState from "../components/EmployeeState.vue";
import Language from "../components/Language.vue";
import Roles from "../components/Roles.vue";
import EmployeeHeader from "../components/EmployeeHeader.vue";
import StartDate from "../components/StartDate.vue";
import DeactivationDate from "../components/DeactivationDate.vue";
import DefualtWarehouse from "../components/DefualtWarehouse.vue";
import DataTableLayout from "../components/DataTableLayout.vue";
import { UserPreferences, useAuth, User, DataTableStyle } from "@cumulus/event-bus";

const { getEmployee, updateEmployee, deleteEmployee, getClients, getWarehouses } = useEmployee();

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const employee = ref<Employee>({} as Employee);
const initialEmployee = ref<Employee | null>(null);
const val = useValidate();
const toast = useCumulusToast(useToast());
const isLoading = ref(true);
const loadFailed = ref(false);
const errorReason = ref("");
const previouslyFocusedInput = ref<HTMLInputElement | null>(null);
const unsavedChangesDialogVisible = ref(false);
const isSaving = ref(false);
const confirmedDiscard = ref(false);

const panels = ref(new Map<string, boolean>([["accessAndPrefrencesPanel", false]]));

const routeToEmployeeSearch = () => {
  confirmedDiscard.value = true;
  router.push({ name: "employee-search" });
};

onBeforeRouteLeave((_to, _from, next) => {
  if (hasUpdates.value && !confirmedDiscard.value) {
    unsavedChangesDialogVisible.value = true;
    next(false);
  } else {
    next();
  }
});

const { getUser, isAuthenticated } = useAuth();

const dataTableStyleComputed = computed<DataTableStyle>({
  get: () => employee.value.preferences.dataTableStyle,
  set: (value: DataTableStyle) => {
    employee.value.preferences.dataTableStyle = value;
    getUser().then((user) => {
      user.setPreference(employee.value.preferences);
      reloadBodyCss(user);
    });
  },
});

const reloadBodyCss = (user: User) => {
  document.body.className = user.getBodyCssClass(isAuthenticated.value);
};

const updateUserPreference = async (preferences: UserPreferences) => {
  const user = await getUser();
  if (!isEqual(user.preference(), preferences)) {
    user.setPreference(preferences);
    reloadBodyCss(user);
  }
};

const onSave = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  isSaving.value = true;
  try {
    await updateEmployee(employee.value);
    await updateUserPreference(employee.value.preferences);

    toast.add({
      severity: "success",
      summary: t("employee.toast.edit.summary"),
      detail: t("employee.toast.edit.detail", { email: employee.value.email }),
      closable: true,
    });

    routeToEmployeeSearch();
  } finally {
    isSaving.value = false;
  }
};

const onDeleteEmployee = async () => {
  isSaving.value = true;
  try {
    await deleteEmployee(employee.value.id);

    toast.add({
      severity: "success",
      summary: t("employee.toast.delete.success.summary"),
      closable: true,
    });

    routeToEmployeeSearch();
  } finally {
    isSaving.value = false;
  }
};

const fetchEmployeeById = async () => {
  try {
    const id = route.params.id as string;

    isLoading.value = true;
    employee.value = await getEmployee(id);
    initialEmployee.value = cloneDeep(employee.value);
    focusFirstInput();
  } finally {
    isLoading.value = false;
  }
};

const clientOptions = ref<Client[]>([]);
const fetchClientOptions = async () => {
  clientOptions.value = await getClients();
};

const roleOptions = ref<Role[]>([]);
const fetchRoleOptions = async () => {
  roleOptions.value = [
    new Role("reader", t(`common.role.reader`)),
    new Role("contributor", t(`common.role.contributor`)),
    new Role("admin", t(`common.role.admin`)),
  ];
};

const warehouseOptions = ref<Warehouse[]>([]);
const fetchWarehouseOptions = async () => {
  warehouseOptions.value = await getWarehouses();
};

const employeeFirstnameRef = ref();
const focusFirstInput = () => {
  employeeFirstnameRef.value?.focus();
};

const hasUpdates = computed(() => {
  return !isEqual(employee.value, initialEmployee.value);
});

const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  if (hasUpdates.value === true) {
    unsavedChangesDialogVisible.value = true;
  } else {
    routeToEmployeeSearch();
  }
};

const stayOnPage = () => {
  unsavedChangesDialogVisible.value = false;
  if (previouslyFocusedInput.value) {
    previouslyFocusedInput.value.focus();
  }
};

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    if (unsavedChangesDialogVisible.value) {
      stayOnPage();
    } else onCancel();
  } else if (event.ctrlKey && event.key === "i" && unsavedChangesDialogVisible.value) {
    routeToEmployeeSearch();
  }
};

onMounted(() => {
  document.addEventListener("keydown", handleKeydown);
  Promise.all([fetchEmployeeById(), fetchWarehouseOptions(), fetchRoleOptions(), fetchClientOptions()]);
});

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});

useShortcut(ShortcutAction.save, onSave);
</script>

<style scoped lang="scss">
.c-edit-employee {
  margin: var(--default-content-margin);
}

.c-border-div {
  padding: 0.5rem;
  border-bottom: var(--footer-border);
}
@media only screen and (min-width: 999px) {
  .c-border-div {
    padding: 0.5rem;
    border-bottom: none;
    border-right: var(--footer-border);
    &:last-child {
      border-right: none;
    }
  }
}
</style>
