<template>
  <label for="client-currency" class="c-under-construction">
    {{ t(`client.currency`) }}
  </label>
  <Dropdown
    id="client-currency"
    :options="currencies"
    v-model="currencyComputed"
    optionLabel="iso"
    optionValue="iso"
    data-testid="client-currency"
    aria-describedby="client-currency-help"
    class="w-full"
    :class="{ 'p-invalid': val.currency.$error }"
    :placeholder="t('placeholder.select', { property: t('client.currency').toLowerCase() })"
    @show="isActive = true"
    @hide="isActive = false"
    @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
  />
  <small data-testid="client-currency-error" class="p-error" v-if="val.currency.$error">{{
    val.currency.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { computed, onMounted, ref } from "vue";
import { currencyRepository } from "@/repositories/currency/CurrencyRepository";
import { useI18n } from "vue-i18n";
import { required } from "@/locales/i18n-validators";
import { Currency } from "@/repositories/currency/model/Currency";

const props = defineProps<{
  currency: string;
}>();

const emit = defineEmits<{
  (e: "update:currency", value: string): void;
}>();

const { t } = useI18n();

const isActive = ref(false);

const currencyComputed = computed<string>({
  get: () => {
    return props.currency;
  },
  set: (value) => {
    emit("update:currency", value);
  },
});

const currencies = ref<Currency[]>([]);
const fetchCurrencies = async () => {
  currencies.value = await currencyRepository.getAll();
};
onMounted(fetchCurrencies);

const rules = {
  currency: {
    required,
  },
};

const val = useVuelidate(rules, props);
</script>
