<template>
  <DataTable
    ref="employeeDataTable"
    :value="employees"
    v-model:expandedRows="expandedRows"
    v-model:selection="selectedEmployee"
    dataKey="id"
    :autoLayout="true"
    class="c-compact-datatable"
    responsiveLayout="scroll"
    selectionMode="single"
    @row-select="onRowSelected"
    @row-dblclick="onRowDblClicked"
    :loading="loading"
    stripedRows
    sortField="name"
    :sortOrder="1"
    :paginator="true"
    :currentPageReportTemplate="
      t('employee.current-page-template', {
        first: '{first}',
        last: '{last}',
        totalRecords: '{totalRecords}',
      })
    "
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rows="pageSize"
    :rowsPerPageOptions="[50, 100]"
    data-testid="employee-table"
    tabindex="0"
    scrollable
    scrollHeight="75vh"
  >
    <Column :header="t('employee.search.header-registration-date')" field="startDate" :sortable="true">
      <template #body="{ data, index }">
        <span :data-testid="`registered-date-${index}`">
          {{ d(data.startDate) }}
        </span>
      </template>
    </Column>
    <Column
      v-for="(col, index) of columns"
      :field="col.field"
      :header="col.header"
      :key="col.field + '_' + index"
      :sortable="true"
    >
      <template #body="{ data, index }">
        <span :data-testid="`${col.dataTestId}-${index}`">
          {{ data[col.field] }}
        </span></template
      >
    </Column>
    <Column :header="t('employee.search.header-default-client')" field="defaultClientId">
      <template #body="{ data, index }">
        <span :data-testid="`default-client-${index}`">
          {{ getDefaultClientName(data.defaultClientId) }}
        </span>
      </template>
    </Column>
    <Column :header="t('employee.search.header-employee-role')" field="roles">
      <template #body="{ data, index }">
        <span v-for="role in data.roles" :key="role" :data-testid="`roles-${index}`">{{ role }}</span>
      </template>
    </Column>
    <Column :header="t('employee.search.header-deactivation-date')" field="deactivationDate" :sortable="true">
      <template #body="{ data, index }">
        <span :data-testid="`deactivation-date-${index}`">
          {{ d(data.deactivationDate) }}
        </span>
      </template>
    </Column>

    <template #empty>{{ t("employee.search.empty-list") }} </template>
    <template #loading>{{ t("employee.search.loading-list") }}</template>
  </DataTable>
</template>
<script setup lang="ts">
import { Employee } from "@/repositories/employee/model/Employee";
import { DataTableColumn } from "@/repositories/employee/model/search/DataTableColumn";
import DataTable, { DataTableRowDoubleClickEvent, DataTableRowSelectEvent } from "primevue/datatable";
import { nextTick, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { NIL as emptyUuid } from "uuid";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { Client } from "@/repositories/employee/client/model/Client";

const router = useRouter();
const toast = useCumulusToast(useToast());
const { t, d } = useI18n();
const expandedRows = ref([]);
const previouslyFocusRow = ref();
const employeeDataTable = ref();
const selectedEmployee = ref<Employee>(new Employee());

const props = defineProps<{
  employees: Employee[];
  clients: Client[];
  loading: boolean;
  totalHits: number;
  pageSize: number;
  page: number;
}>();

defineEmits<{
  (e: "update:pageSize", value: number): void;
}>();

watch([() => props.employees, () => props.loading], ([employees, loading]) => {
  if (loading === false && employees.length > 0) {
    nextTick(() => {
      focusSearchResult();
    });
  } else {
    clearFocusedRow();
  }
});
const clearFocusedRow = () => {
  previouslyFocusRow.value = null;
  selectedEmployee.value = new Employee();
};

const columns = ref<DataTableColumn[]>([
  { field: "firstName", header: t("employee.search.header-first-name"), dataTestId: "first-name" },
  { field: "lastName", header: t("employee.search.header-last-name"), dataTestId: "last-name" },
  { field: "initials", header: t("employee.search.header-initials"), dataTestId: "initials" },
  { field: "phoneNumber", header: t("employee.search.header-phone-number"), dataTestId: "phone-number" },
  { field: "email", header: t("employee.search.header-email"), dataTestId: "email" },
  { field: "employeeState", header: t("employee.search.header-employee-status"), dataTestId: "employee-status" },
] as DataTableColumn[]);

const getDefaultClientName = (clientId: string) => {
  const client = props.clients.find((client) => client.id === clientId);
  return client ? client.name : "";
};

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }
  if (event.originalEvent.key !== "Enter") {
    return;
  }
  setFocusedRow(event.index);
  openEmployee(event.data.id);
};

const onRowDblClicked = (event: DataTableRowDoubleClickEvent) => {
  setFocusedRow(event.index);
  openEmployee(event.data.id);
};

const openEmployee = (employeeId: string) => {
  employeeId != null && employeeId !== emptyUuid
    ? router.push({ name: "employee-edit", params: { id: employeeId } })
    : toast.add({
        severity: "warn",
        summary: t("employee.toast.open-employee-failed"),
        detail: t("employee.search-header.no-employee-selected"),
        closable: true,
      });
};

const setFocusedRow = (index: number) => {
  const pageIndex = (props.page - 1) * props.pageSize;
  const rowIndex = index - pageIndex;
  previouslyFocusRow.value = employeeDataTable.value.$el.querySelector(`tbody tr:nth-child(${rowIndex + 1})`);
};

const focusSearchResult = () => {
  if (props.employees.length > 0 && employeeDataTable.value) {
    if (previouslyFocusRow.value) {
      nextTick(() => {
        previouslyFocusRow.value.focus();
      });
    } else {
      employeeDataTable.value?.$el.querySelector("tbody tr:first-of-type").focus();
    }
  }
};
const exportCSV = () => {
  employeeDataTable.value.exportCSV();
};
defineExpose({ exportCSV });
</script>
<style scoped lang="scss">
:deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-current {
    margin-left: auto;
  }
}
</style>
