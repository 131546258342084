<template>
  <div class="c-toolbar">
    <div class="c-header-icon-container">
      <PrimeButton class="c-circular-button" @click="onClose" data-testid="import-close-button">
        <i class="pi pi-times c-cancel-button c-circular-icon"></i>
        <span class="px-3">{{ t("common.close") }}</span>
      </PrimeButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const emit = defineEmits<{
  (e: "onClose"): void;
}>();
const { t } = useI18n();

const onClose = () => {
  emit("onClose");
};
</script>

<style scoped lang="scss">
.c-cancel-button {
  background-color: var(--warning-btn-bg);
  border: none;
}
</style>
