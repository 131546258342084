<template>
  <div class="c-import">
    <div class="mt-4">
      <ImportToolbar @onClose="routeToDashboard" />
    </div>
    <div class="c-card col-12 h-auto">
      <div class="col-12 md:col-12 lg:col-6">
        <label class="font-medium mb-2">{{ t(`import.company-name`, { companyName: companyName }) }} </label>
      </div>
      <div class="col-12">
        <label class="font-normal font-italic">
          {{ t("import.important-requirement") }}
          <a href="/freight/methods"> {{ t("import.shipping-method") }}</a>
        </label>
      </div>

      <div class="col-12">
        <FileUpload
          mode="basic"
          accept=".csv"
          chooseLabel="1. Import supplier CSV file"
          class="w-4 text-left"
          customUpload
          @uploader="uploader($event as unknown as FileUploadUploadEvent, ImportType.Supplier)"
          :disabled="uploadInProgress"
          :auto="true"
        >
        </FileUpload>
      </div>

      <div class="col-12">
        <FileUpload
          mode="basic"
          accept=".csv"
          chooseLabel="2. Import B2B Customer file"
          class="w-4 text-left"
          customUpload
          @uploader="uploader($event as unknown as FileUploadUploadEvent, ImportType.B2BCustomer)"
          :disabled="uploadInProgress"
          :auto="true"
        >
        </FileUpload>
      </div>

      <div class="col-12">
        <FileUpload
          mode="basic"
          accept=".csv"
          chooseLabel="3. Import Product file"
          class="w-4 text-left"
          customUpload
          @uploader="uploader($event as unknown as FileUploadUploadEvent, ImportType.Products)"
          :disabled="uploadInProgress"
          :auto="true"
        >
        </FileUpload>
      </div>
      <div class="col-12 font-bold">
        <div v-if="importResultMessage.status === 400" class="h-full">
          <div>
            {{ t("import.error", { importType: importType }) }}
          </div>
          <div v-for="(error, index) in importResultMessage.message" :key="index">
            <span class="mr-2">{{ error.errorKey }}</span>
            <span>{{ error.errorMessage }}</span>
          </div>
        </div>

        <div v-if="importResultMessage.status === 202">
          {{ t("import.success", { importType: importType }) }}
        </div>

        <div v-show="uploadInProgress" class="mr-4">
          {{ t("import.importing") }}
        </div>
        <div v-if="uploadInProgress">
          <ProgressSpinner style="width: 3rem; height: 3rem"></ProgressSpinner>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { FileUploadUploadEvent } from "primevue/fileupload";

import ImportToolbar from "./components/ImportToolbar.vue";

import { useCompany } from "@/repositories/company/CompanyService";
import { useImport } from "@/repositories/import/ImportService";
import { ImportType } from "@/repositories/import/model/ImportType";
import { ImportResultMessage } from "@/repositories/import/model/ImportResultMessage";

const { t } = useI18n();

const { getCompany } = useCompany();
const { importCsvFile } = useImport();

const router = useRouter();

const companyName = ref<string>("");
const importType = ref<ImportType>(ImportType.Supplier);
const importResultMessage = ref<ImportResultMessage>(new ImportResultMessage());

const loadCompany = async () => {
  companyName.value = (await getCompany()).companyName;
};
onMounted(loadCompany);

const routeToDashboard = () => {
  router.push("/");
};

const uploadInProgress = ref(false);
const uploader = async (event: FileUploadUploadEvent, type: ImportType) => {
  const files = event.files;
  importType.value = type;
  importResultMessage.value = new ImportResultMessage();

  if (files !== undefined && files !== null) {
    uploadInProgress.value = true;

    try {
      for (const file of files as File[]) {
        importResultMessage.value = await importCsvFile(file, type);
      }
    } catch (error: ImportResultMessage | unknown) {
      importResultMessage.value = error as ImportResultMessage;
    } finally {
      uploadInProgress.value = false;
    }
  }
};
</script>

<style lang="scss">
.c-import {
  margin: var(--default-content-margin);
}

.p-fileupload-choose .p-button-icon-left {
  margin-right: 2rem;
}
</style>
