{
  "validations": {
    "required": "Verdien er påkrevd",
    "email": "Må være en gyldig e-post adresse",
    "min-length": "{property} lengde er {min}",
    "exists": "{property} finnes fra før"
  },
  "placeholder": {
    "select": "Velg {property}",
    "type": "Skriv inn {property}"
  },
  "common": {
    "created": "Opprettet",
    "add": "Legg til",
    "cancel": "Avbryt",
    "update": "Oppdater",
    "create": "Opprett",
    "delete": "Slett",
    "close": "Lukk",
    "search": "Søk",
    "date": "Dato",
    "valid-date": "Gyldig dato",
    "reload": "Last inn på nytt",
    "show-message": "Vis melding",
    "error": "En feil oppsto",
    "error-load": "Kunne ikke laste inn {entity}, grunn: {errorReason}",
    "unsaved-changes-header": "Ulagrede endringer",
    "unsaved-changes-text": "Du har endringer som ikke er lagret. Lagre dine endringene eller Forkast for å forlate uten å lagre. Velg Avbryt for a fortsette redigeringen",
    "discard": "Forkast",
    "save": "Lagre",
    "current-page-template": "Viser {first} til {last} av totalt {totalRecords}",
    "save-tooltip": "Ingen endringer",
    "message": {
      "validate-error": "Validering feilet, rett alle røde felter og prøv igjen"
    },
    "validation-error": {
      "summary": "Validering feilet",
      "detail": "Rett alle rød felter og prøv igjen"
    },
    "error-detail": {
      "error-occured": "En feil oppsto",
      "unknown": "Ukjent feil",
      "server-error": "Server feil",
      "not-found": "Ikke funnet",
      "bad-request": "Server kan ikke behandle forespørselen"
    },

    "role": {
      "reader": "Leser",
      "contributor": "Bidragsyter",
      "admin": "Administrator"
    },
    "address": {
      "add": "Legg til adresse",
      "edit": "Rediger adresse",
      "address-lines": "Adresselinjer",
      "address-lines-error": "Minst en adresselinje er påkrevd",
      "postal-code-city": "Postnr./sted",
      "city": "By",
      "country": "Land"
    },
    "filters": {
      "show-filter": "Vis filter",
      "hide-filter": "Skjul filter",
      "clear-filters": "Tøm filtre",
      "filter": "Filter",
      "filter-employee-status": "Ansatt status",
      "filter-status-active": "Active",
      "filter-status-inactive": "Inactive",
      "filter-clients": "Kunder",
      "filter-roles": "Roller",
      "filter-deactivation-date": "Deaktiveringsdato",
      "filter-date-from": "Fra",
      "filter-date-to": "Til"
    }
  },

  "employee": {
    "employee": "Ansatt",
    "employees": "Ansatte",
    "search-employee": "Søk Ansatt",
    "add-employee": "Legg Til Ansatt",
    "edit-employee": "Endre Ansatt",
    "export": "Export",
    "languages": {
      "no": "Norsk",
      "en": "Engelsk"
    },
    "id": "BrukerId",
    "email": "Brukernavn / Epost",
    "roles": "Roller",
    "clients": "Klient",
    "default-client": "Standard klient",
    "warehouse": "Lager",
    "default-warehouse": "Standard lager",
    "first-name": "Fornavn",
    "last-name": "Etternavn",
    "employee-state": "Ansatt status",
    "status": {
      "active": "Aktiv",
      "inactive": "Inaktiv"
    },
    "language": "Språk",
    "phone-number": "Telefonnummer",
    "start-date": "Startdato",
    "deactivation-date": "Avsluttningsdato",
    "initials": "Initialer",
    "active": "Aktiv",
    "cancel": "Avbryt",
    "update": "Oppdater",
    "add": "Legg til",
    "delete": {
      "header": "Slett ansatt",
      "dialog": "Er du sikker på at du vil slette denne ansatte?"
    },
    "created-message": "Ny ansatt opprettet: ",
    "toast": {
      "edit": {
        "summary": "Ansatt oppdatert",
        "detail": "For ansatt {email}"
      },
      "delete": {
        "success": {
          "summary": "Ansatt slettet"
        },
        "error": {
          "summary": "Kunne ikke slette ansatt"
        }
      },
      "reload": {
        "summary": "Laster inn ansatt på nytt, vennligst vent..."
      },
      "load": {
        "error": {
          "summary": "Kunne ikke laste inn ansatte"
        }
      },
      "open-employee-failed": "Kunne ikke åpne ansatt"
    },
    "search": {
      "filter": "Filtrer etter nøkkelord",
      "search-label": "Ansatt søk:",
      "more-filters": "Flere filtre",
      "total-hits": "{totalHits} ansatte funnet",
      "add-btn": "Ny ansatt",
      "advanced-btn": "Avansert",
      "select-all": "Velg alle",
      "delete-selected": "Slett valgte",
      "edit-columns": "Tilpass kolonner",
      "header-first-name": "Fornavn",
      "header-last-name": "Etternavn",
      "header-initials": "Initialer",
      "header-phone-number": "Telefonnummer",
      "header-deactivation-date": "Deaktiveringsdato",
      "header-registration-date": "Registrert",
      "header-email": "Epost",
      "header-default-client": "Standard kunde",
      "header-employee-status": "Status",
      "header-employee-role": "Roller",
      "search-error": "Søk feilet",
      "empty-list": "Ingen ansatte funnet",
      "loading-list": "Laster inn ansatte, vennligst vent..."
    },
    "common": {
      "add": "Legg til",
      "cancel": "Avbryt",
      "search": "Søk",
      "clear": "Tøm",
      "yes": "Ja",
      "no": "Nei",
      "save": "Lagre"
    },
    "panels": {
      "access-and-prefrence": "Tilgang og preferanser"
    },
    "current-page-template": "Viser {first} til {last} av totalt {totalRecords} ansatte",
    "datatable-style": "Datatabell stil",
    "datatable-style-type": {
      "default": "Standard",
      "gridlines": "Rutenett rader"
    }
  },
  "company": {
    "header-info": "Firma informasjon",
    "info": {
      "toast": {
        "success": {
          "summary": "Firma er oppdatert",
          "detail": "Endringene er straks klare"
        },
        "error": {
          "summary": "Oppdatering feilet",
          "detail": "Endringene er ikke lagret"
        }
      }
    },
    "name": "Navn",
    "email": "E-post",
    "organization-number": "Organisasjonsnummer",
    "registration-date": "Registreringsdato",
    "telephone-number": "Telefonnummer"
  },
  "client": {
    "import-logo": "Importer logo",
    "logo": "Logo",
    "client": "Klient",
    "clients": "Klienter",
    "search-client": "Søk Klient",
    "add-client": "Legg Til Klient",
    "edit-client": "Endre Klient",
    "export": "Export",
    "confirm-delete": "Er du sikker på at du vil slette denne klienten?",
    "shipping-address": "Sendingsadresse",
    "name": "Klientnavn",
    "business-number": "Bedriftsnummer",
    "phone-number": "Telefonnummer",
    "client-status": "Klientstatus",
    "client-address": "Klient adresse",
    "email": "Epost",
    "currency": "Valuta",
    "country": "Land",
    "delete": {
      "dialog": "Er du sikker på at du vil slette denne klienten?"
    },
    "banking": {
      "header": "Bankinformasjon",
      "name": "Navn",
      "account-number": "Kontonummer"
    },
    "message": {
      "reloading-client": "Laster inn klienten på nytt, vennligst vent...",
      "message-info": "Under konstruksjon",
      "success-add": "Klient opprettet!",
      "success-update": "Klient oppdatert!",
      "success-delete": "Klient slettet!",
      "error-load": "Kunne ikke laste inn klienter, vennligst prøv igjen senere",
      "error-add": "Kunne ikke opprette klient, vennligst prøv igjen senere",
      "error-update": "Kunne ikke oppdatere klient, vennligst prøv igjen senere",
      "error-delete": "Kunne ikke slette klient, vennligst prøv igjen senere"
    },
    "toast": {
      "success": {
        "summary": "Klient oppdatert",
        "detail": "For klient {clientname}"
      },
      "reload": {
        "summary": "Laster inn klienten på nytt, vennligst vent..."
      },
      "load": {
        "error": {
          "summary": "Kunne ikke laste inn klienter"
        }
      },
      "error": {
        "summary": "En feil oppsto",
        "detail": "Kunne ikke oppdatere klienten, vennligst prøv igjen senere",
        "file-upload-failed": "Filopplasting feilet",
        "file-upload-failed-detail": "Kunne ikke laste opp filen, vennligst prøv igjen senere"
      },
      "delete": {
        "success": {
          "summary": "Klient slettet",
          "warning": "For klient {clientName}"
        },
        "warn": {
          "summary": "En feil oppsto",
          "detail": "Kunne ikke slette klienten, vennligst prøv igjen senere"
        }
      },
      "open-client-failed": "Kunne ikke åpne kunde",
      "no-client-selected": "Kunde ikke valgt"
    },
    "status": {
      "active": "Aktiv",
      "inactive": "Inaktiv"
    },
    "search": {
      "filter": "Filtrer etter nøkkelord",
      "search-label": "Klientsøk:",
      "more-filters": "Flere filtre",
      "total-hits": "{totalHits} klienter funnet",
      "advanced-btn": "Avansert",
      "select-all": "Velg alle",
      "delete-selected": "Slett valgte",
      "edit-columns": "Tilpass kolonner",
      "header-name": "Klientnavn",
      "header-client-status": "Klientstatus",
      "search-error": "Søk feilet",
      "empty-list": "Ingen klienter funnet.",
      "loading-list": "Laster inn klienter, vennligst vent...",
      "current-page": "Viser {first} til {last} av {totalRecords}",
      "header-registered": "Registrert",
      "header-business-number": "Bedriftsnummer",
      "header-phone": "Telephonenummer",
      "header-email": "E-post",
      "header-country": "Land"
    },
    "panels": {
      "general-panel": "Generell"
    },
    "invalid-file-size": "Ugyldig filstørrelse. Maks størrelse er",
    "invalid-file-type": "Ugyldig filtype. Støttede filtyper er {fileTypes}"
  },
  "import": {
    "company-name": "Import for {companyName}",
    "importing": "Importerer...",
    "success": "Vellykket import av {importType}",
    "error": "Import feilet for {importType}",    
    "important-requirement": "Viktig krav - Legg til forsendelsesmetode \"Pick up\" manuelt etter at et nytt firma er etablert. Link:",
    "shipping-method": "Fraktmetode"
  },

  "settings": {
    "settings-header": "Instillinger",
    "freight": {
      "header": "Frakt",
      "freight-method": "Fraktmåter"
    },
    "warehouse": {
      "header": "Lager",
      "warehouse-items": "Lager konfigurasjon"
    },
    "attribute": {
      "header": "Attributt",
      "attributes": "Attributter"
    },
    "import": {
      "header": "Import",
      "imports": "Importer"
    }
  }
}
