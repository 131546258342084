<template>
  <label for="employee-state">
    {{ t("employee.employee-state") }}
  </label>
  <Dropdown
    id="employee-state"
    v-model="employeeStateComputed"
    :options="options"
    optionLabel="label"
    optionValue="value"
    class="w-full"
    data-testid="employee-state"
    @show="isActive = true"
    @hide="isActive = false"
    @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
  />
</template>

<script setup lang="ts">
import { EmployeeState } from "@/repositories/employee/model/EmployeeState";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  employeeState: EmployeeState;
}>();

const emit = defineEmits<{
  (e: "update:employeeState", value: string): void;
}>();

const isActive = ref(false);

const options = ref<Record<string, string>[]>([]);
const { t } = useI18n();

const employeeStateComputed = computed<string>({
  get: () => {
    return props.employeeState ?? EmployeeState.Active;
  },
  set: (value) => {
    emit("update:employeeState", value);
  },
});

const fetchEmployeeStatus = () => {
  for (const state in EmployeeState) {
    options.value.push({
      label: t(`employee.status.${state.toString().toLowerCase()}`),
      value: state,
    });
  }
};

onMounted(fetchEmployeeStatus);
</script>
