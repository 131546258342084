<template>
  <label for="email"> {{ t("employee.email") }} </label>
  <InputText
    id="email"
    type="email"
    v-model="emailComputed"
    data-testid="email"
    class="inputfield w-full"
    :class="{ 'p-invalid': val.email.$error }"
    :placeholder="t('placeholder.select', { property: t('employee.email').toLowerCase() })"
    maxlength="8000"
  />
  <small class="p-error" v-if="val.email.$error" data-testid="email-error">{{ val.email.$errors[0].$message }}</small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { required, email as validateEmail } from "@/locales/i18n-validators";
import { computed } from "vue";
import { helpers } from "@vuelidate/validators";

const props = defineProps<{
  email: string;
}>();

const emit = defineEmits<{
  (e: "update:email", value: string): void;
}>();

const { t } = useI18n();

const emailComputed = computed<string>({
  get: () => {
    return props.email ?? "";
  },
  set: (value) => {
    emit("update:email", value);
  },
});

const rules = {
  email: {
    required,
    validateEmail: helpers.withMessage(() => t("validations.email"), validateEmail),
  },
};

const val = useValidate(rules, props);
</script>
