<template>
  <label for="last-name"> {{ t("employee.last-name") }} </label>
  <InputText
    id="last-name"
    type="text"
    v-model="lastNameComputed"
    data-testid="last-name"
    class="inputfield w-full"
    :class="{ 'p-invalid': val.lastName.$error }"
    :placeholder="t('placeholder.select', { property: t('employee.last-name').toLowerCase() })"
    maxlength="8000"
  />
  <small class="p-error" v-if="val.lastName.$error" data-testid="last-name-error">{{
    val.lastName.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { computed } from "vue";

const props = defineProps<{
  lastName: string;
}>();

const emit = defineEmits<{
  (e: "update:lastName", value: string): void;
}>();

const { t } = useI18n();

const lastNameComputed = computed<string>({
  get: () => {
    return props.lastName ?? "";
  },
  set: (value) => {
    emit("update:lastName", value);
  },
});

const rules = {
  lastName: {
    required,
  },
};

const val = useValidate(rules, props);
</script>
