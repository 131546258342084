<template>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <PrimeButton
          class="c-circular-button mr-3"
          @click="emit('refreshClicked')"
          data-testid="printing-refresh-button"
        >
          <i class="pi pi-refresh c-default-button c-circular-icon"></i>
          <span class="px-3">{{ t("common.reload") }}</span>
        </PrimeButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const emit = defineEmits<{
  (e: "refreshClicked"): void;
}>();

const { t } = useI18n();
</script>
