<template>
  <CumulusDatePicker
    v-model:date="startDateComputed"
    :id="'start-date'"
    :dataTestId="'start-date'"
    :label="t('employee.start-date')"
  />

  <small class="p-error" v-if="val.startDate.$error" data-testid="start-date-error">{{
    val.startDate.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import { computed } from "vue";
import useValidate from "@vuelidate/core";
import { useI18n } from "vue-i18n";
import { required } from "@/locales/i18n-validators";
import { CumulusDatePicker } from "@cumulus/components";

const props = defineProps<{
  startDate: string;
}>();

const emit = defineEmits<{
  (e: "update:startDate", value: string): void;
}>();

const { t } = useI18n();

const startDateComputed = computed({
  get: () => props.startDate,
  set: (value) => {
    emit("update:startDate", value);
  },
});

const rules = {
  startDate: {
    required,
  },
};

const val = useValidate(rules, props);
</script>
