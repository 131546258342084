<template>
  <BeforeUnloadBrowserTab :hasUpdates="hasUpdates" />
  <EmployeeHeader
    :editMode="false"
    :hasUpdates="hasUpdates"
    :unsavedChangesDialogVisible="unsavedChangesDialogVisible"
    :isSaving="isSaving"
    @stayOnPage="stayOnPage"
    @onCommit="onCreate"
    @onCancel="onCancel"
    @routeToEmployeeSearch="routeToEmployeeSearch"
  />
  <div class="c-add-employee">
    <Message v-for="employee of employeesCreated" severity="info" :key="employee.id"
      >{{ t("employee.created-message")
      }}<a class="employee-created-link" :href="`/system-data/employee/${employee.id}`" :closable="true">{{
        employee.firstName + " " + employee.lastName
      }}</a></Message
    >

    <div class="grid">
      <div class="flex col-12">
        <div class="c-card w-full">
          <div class="formgrid grid">
            <div class="field col-12 md:col-3">
              <FirstName v-model:firstName="employee.firstName" ref="employeeFirstnameRef" />
            </div>
            <div class="field col-12 md:col-4">
              <LastName v-model:lastName="employee.lastName" />
            </div>

            <div class="field col-12 md:col-3">
              <Initials v-model:initials="employee.initials" />
            </div>
            <div class="field col-12 md:col-2">
              <Language v-model:languageCode="employee.languageCode" />
            </div>

            <div class="field col-12 md:col-7">
              <Email v-model:email="employee.email" />
            </div>
            <div class="field col-12 md:col-3">
              <PhoneNumber v-model:phoneNumber="employee.phoneNumber" />
            </div>
            <div class="field col-12 md:col-2">
              <EmployeeState :employeeState="employee.employeeState" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="grid">
      <div class="flex col-12">
        <CumulusPanel
          toggleTestId="c-access-and-prefrences-panel"
          :collapsed="panels.get('accessAndPrefrencesPanel')"
          @onCollapsedChange="panels.set('accessAndPrefrencesPanel', $event)"
        >
          <template #title> {{ t("employee.panels.access-and-prefrence") }} </template>
          <template #content>
            <div class="grid">
              <div class="feild col-12 lg:col c-border-div">
                <div class="text-center mt-2">
                  <label for="valid-date">{{ t("common.valid-date") }}</label>
                </div>
                <div class="p-2">
                  <StartDate v-model:startDate="employee.startDate" />
                </div>
                <div class="p-2">
                  <DeactivationDate v-model:deactivationDate="employee.deactivationDate" />
                </div>
              </div>
              <div class="col-12 lg:col c-border-div p-4">
                <div class="text-center">
                  <label for="valid-date">{{ t("employee.roles") }}</label>
                </div>
                <Roles v-model:roles="employee.roles" :roleOptions="roleOptions" />
              </div>

              <div class="col-12 lg:col c-border-div p-4">
                <div class="text-center">
                  <label for="valid-date">{{ t("employee.clients") }}</label>
                </div>
                <div class="p-2">
                  <Clients v-model:clients="employee.clients" :clientOptions="clientOptions" />
                </div>
                <div class="p-2">
                  <DefaultClient
                    v-model:defaultClientId="employee.defaultClientId"
                    :clientOptions="clientOptions.filter((client) => employee.clients.includes(client.id))"
                  />
                </div>
              </div>
              <div class="col-12 lg:col p-4">
                <div class="text-center">
                  <label for="valid-date">{{ t("employee.warehouse") }}</label>
                </div>
                <div class="p-2">
                  <DefualtWarehouse
                    v-model:defaultWarehouseId="employee.warehouseId"
                    :warehouseOptions="warehouseOptions"
                  />

                  <div class="mt-4 mb-4 -mx-4">
                    <DataTableLayout v-model:dataTabelStyle="employee.preferences.dataTableStyle" />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </CumulusPanel>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ref, onMounted, computed, onBeforeUnmount } from "vue";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import useValidate from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { ShortcutAction, useShortcut } from "@cumulus/shortcut";
import { CumulusPanel } from "@cumulus/panel";
import { BeforeUnloadBrowserTab } from "@cumulus/components";
import isEqual from "lodash.isequal";

import { NewEmployee } from "@/repositories/employee/model/NewEmployee";
import { Client } from "@/repositories/employee/client/model/Client";
import { Role } from "@/repositories/employee/role/model/Role";
import { Warehouse } from "@/repositories/employee/model/Warehouse";

import { useEmployee } from "@/repositories/employee/EmployeeService";

import FirstName from "../components/FirstName.vue";
import LastName from "../components/LastName.vue";
import PhoneNumber from "../components/PhoneNumber.vue";
import Initials from "../components/Initials.vue";
import Email from "../components/Email.vue";
import Clients from "../components/Clients.vue";
import DefaultClient from "../components/DefaultClient.vue";
import Language from "../components/Language.vue";
import Roles from "../components/Roles.vue";
import EmployeeHeader from "../components/EmployeeHeader.vue";
import StartDate from "../components/StartDate.vue";
import DeactivationDate from "../components/DeactivationDate.vue";
import DefualtWarehouse from "../components/DefualtWarehouse.vue";
import EmployeeState from "../components/EmployeeState.vue";

const { createEmployee, getClients, getWarehouses } = useEmployee();

const { t } = useI18n();
const toast = useCumulusToast(useToast());
const router = useRouter();
const employee = ref(new NewEmployee());
const initialEmployee = ref<NewEmployee>(new NewEmployee());
const previouslyFocusedInput = ref<HTMLInputElement | null>(null);
const unsavedChangesDialogVisible = ref(false);
const isSaving = ref(false);
const employeesCreated = ref<NewEmployee[]>([]);
const confirmedDiscard = ref(false);

const panels = ref(new Map<string, boolean>([["accessAndPrefrencesPanel", false]]));

onMounted(() => {
  employee.value.languageCode = "NO";
});

onBeforeRouteLeave((_to, _from, next) => {
  if (hasUpdates.value && !confirmedDiscard.value) {
    unsavedChangesDialogVisible.value = true;
    next(false);
  } else {
    next();
  }
});

const routeToEmployeeSearch = () => {
  confirmedDiscard.value = true;
  router.push({ name: "employee-search" });
};

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const val = useValidate();

const onCreate = async () => {
  //Wait for onblur functions
  await timeout(300);

  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }
  isSaving.value = true;
  try {
    await createEmployee(employee.value);

    employeesCreated.value.push(employee.value);
    employee.value = new NewEmployee();
    initialEmployee.value = new NewEmployee();
    val.value.$reset();
    window.scrollTo(0, 0);
    focusFirstInput();
  } finally {
    isSaving.value = false;
  }
};

const clientOptions = ref<Client[]>([]);
const fetchClientOptions = async () => {
  clientOptions.value = await getClients();
};

const roleOptions = ref<Role[]>([]);
const fetchRoleOptions = async () => {
  roleOptions.value = [
    new Role("reader", t(`common.role.reader`)),
    new Role("contributor", t(`common.role.contributor`)),
    new Role("admin", t(`common.role.admin`)),
  ];
};

const warehouseOptions = ref<Warehouse[]>([]);
const fetchWarehouseOptions = async () => {
  warehouseOptions.value = await getWarehouses();
};

const employeeFirstnameRef = ref();
const focusFirstInput = () => {
  employeeFirstnameRef.value.focus();
};

onMounted(focusFirstInput);

const hasUpdates = computed(() => {
  return !isEqual(employee.value, initialEmployee.value);
});

const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  if (hasUpdates.value === true) {
    unsavedChangesDialogVisible.value = true;
  } else {
    routeToEmployeeSearch();
  }
};

const stayOnPage = () => {
  unsavedChangesDialogVisible.value = false;
  if (previouslyFocusedInput.value) {
    previouslyFocusedInput.value.focus();
  }
};

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    if (unsavedChangesDialogVisible.value) {
      stayOnPage();
    } else onCancel();
  } else if (event.ctrlKey && event.key === "i" && unsavedChangesDialogVisible.value) {
    routeToEmployeeSearch();
  }
};

onMounted(() => {
  document.addEventListener("keydown", handleKeydown);
  Promise.all([focusFirstInput(), fetchWarehouseOptions(), fetchRoleOptions(), fetchClientOptions()]);
});

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});

useShortcut(ShortcutAction.save, onCreate);
</script>

<style scoped lang="scss">
.c-add-employee {
  margin: var(--default-content-margin);
}
.c-border-div {
  padding: 0.5rem;
  border-bottom: var(--footer-border);
}

:deep(.p-message .p-message-wrapper) {
  padding: 0.5rem 1.5rem;
}
:deep(.p-message.p-message-info) {
  background: var(--c-blue-100);
}

@media only screen and (min-width: 999px) {
  .c-border-div {
    padding: 0.5rem;
    border-bottom: none;
    border-right: var(--footer-border);
    &:last-child {
      border-right: none;
    }
  }
}
</style>
