<template>
  <BeforeUnloadBrowserTab :hasUpdates="hasUpdates" />
  <ClientHeader
    :editMode="false"
    :hasUpdates="hasUpdates"
    :unsavedChangesDialogVisible="unsavedChangesDialogVisible"
    :isSaving="isSaving"
    @onCommit="onCreate"
    @onCancel="onCancel"
    @routeToClientSearch="routeToClientSearch"
    @onMessage="onMessage"
  />

  <div class="c-add-client">
    <Message
      v-if="showMessage"
      :severity="msg.severity"
      :life="msg.life"
      :sticky="msg.sticky"
      :closable="msg.closeable"
      >{{ msg.content }}</Message
    >
    <div class="grid">
      <div class="flex col-12">
        <div class="c-card w-full">
          <div class="formgrid grid">
            <div class="field col-12 md:col-6">
              <Name v-model:name="client.name" />
            </div>
            <div class="field col-12 md:col-3">
              <BusinessNumber v-model:businessNumber="client.businessNumber" />
            </div>
            <div class="field col-12 md:col-3">
              <Country v-model:country="client.countryIso" :countryOptions="countryOptions" />
            </div>
            <div class="field col-12 md:col-4">
              <Email v-model:email="client.email" />
            </div>
            <div class="field col-12 md:col-2">
              <PhoneNumber v-model:phoneNumber="client.phoneNumber" />
            </div>
            <div class="field col-12 md:col-3">
              <ClientStatus v-model:clientState="client.clientState" />
            </div>
            <div class="field col-12 md:col-3">
              <Currency v-model:currency="client.currencyIso" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="grid">
      <div class="flex col-12">
        <CumulusPanel
          toggle-test-id="c-general-panel"
          :collapsed="panels.get('generalPanel')"
          @onCollapsedChange="panels.set('generalPanel', $event)"
        >
          <template #title> {{ t("client.panels.general-panel") }} </template>
          <template #content>
            <div class="grid">
              <div class="field col-12 lg:col c-border-div pr-3" data-testid="client-address">
                <div class="text-center mt-2">
                  <label for="client-address">{{ t("client.client-address") }}</label>
                </div>
                <Address
                  v-model:address="client.address"
                  :countryOptions="countryOptions"
                  :addressType="clientAddress"
                />
              </div>

              <div class="col-12 lg:col pl-3 c-border-div pr-3" data-testid="banking-address">
                <div class="text-center mt-2">
                  <label for="bank-info">{{ t("client.banking.header") }}</label>
                </div>
                <Banking v-model:banking="client.banking" />
              </div>

              <LogoUpload :logo="client.logoBase64" @update:logo="client.logoBase64 = $event" />
            </div>
          </template>
        </CumulusPanel>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import useValidate from "@vuelidate/core";
import { useI18n } from "vue-i18n";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import { CumulusPanel } from "@cumulus/panel";
import { BeforeUnloadBrowserTab } from "@cumulus/components";
import isEqual from "lodash.isequal";

import { Client } from "@/repositories/client/model/Client";
import { Message as MessageModel } from "@/repositories/client/model/message/Message";
import { MessageSeverityType } from "@/repositories/client/model/message/MessageSeverityType";

import Name from "../components/Name.vue";
import BusinessNumber from "../components/BusinessNumber.vue";
import Country from "../components/Country.vue";
import Email from "../components/Email.vue";
import PhoneNumber from "../components/PhoneNumber.vue";
import Currency from "../components/Currency.vue";
import Banking from "../components/Banking.vue";
import Address from "../components/Address.vue";
import ClientHeader from "../components/ClientHeader.vue";
import ClientStatus from "../components/ClientStatus.vue";

import { useCountry } from "@/repositories/country/CountryService";
import { Country as CountryModel } from "@/repositories/country/model/Country";
import { useClient } from "@/repositories/client/ClientService";
import LogoUpload from "../components/LogoUpload.vue";

const { t } = useI18n();
const router = useRouter();
const showMessage = ref(false);
const msg = ref<MessageModel>({} as MessageModel);

const countryOptions = ref<CountryModel[]>([]);
const previouslyFocusedInput = ref<HTMLInputElement | null>(null);
const unsavedChangesDialogVisible = ref(false);
const isSaving = ref(false);
const client = ref<Client>(new Client());
const initialClient = ref<Client>(new Client());
const panels = ref(new Map<string, boolean>([["generalPanel", false]]));
const clientAddress = ref<string>("client-address");
const confirmedDiscard = ref(false);
const val = useValidate();

const routeToClientSearch = () => {
  confirmedDiscard.value = true;
  router.push({ name: "client-search" });
};

onBeforeRouteLeave((_to, _from, next) => {
  if (hasUpdates.value && !confirmedDiscard.value) {
    unsavedChangesDialogVisible.value = true;
    next(false);
  } else {
    next();
  }
});

// TODO: Only a demo, might be moved to Layout MF later
const onMessage = () => {
  showMessage.value = true;

  msg.value.content = t("client.message.message-info");
  msg.value.life = 10000;
  msg.value.severity = MessageSeverityType.info;
  msg.value.sticky = false;
  msg.value.closeable = true;

  setTimeout(() => {
    showMessage.value = false;
  }, 10000);
};

// TODO: Only a demo, might be moved to Layout MF later
const addMessage = (content: string, severity: MessageSeverityType) => {
  showMessage.value = true;

  msg.value.content = content;
  msg.value.life = 3000;
  msg.value.severity = severity;
  msg.value.sticky = true;
  msg.value.closeable = false;

  setTimeout(() => {
    showMessage.value = false;
  }, 3000);
};

const { createClient } = useClient();

const onCreate = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    addMessage(t("common.message.validate-error"), MessageSeverityType.warn);
    return;
  }
  isSaving.value = true;

  try {
    await createClient(client.value);

    addMessage(t("client.message.success-add"), MessageSeverityType.success);
    setTimeout(() => {
      routeToClientSearch();
    }, 3000);
  } finally {
    isSaving.value = false;
  }
};

const { getCountries } = useCountry();

const fetchCountryData = async () => {
  countryOptions.value = await getCountries();
};

const hasUpdates = computed(() => {
  return !isEqual(client.value, initialClient.value);
});

const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  if (hasUpdates.value === true) {
    unsavedChangesDialogVisible.value = true;
  } else {
    routeToClientSearch();
  }
};

const stayOnPage = () => {
  unsavedChangesDialogVisible.value = false;
  if (previouslyFocusedInput.value) {
    previouslyFocusedInput.value.focus();
  }
};
const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    if (unsavedChangesDialogVisible.value) {
      stayOnPage();
    } else onCancel();
  } else if (event.ctrlKey && event.key === "i" && unsavedChangesDialogVisible.value) {
    routeToClientSearch();
  }
};

onMounted(() => {
  document.addEventListener("keydown", handleKeydown);
  fetchCountryData();
});
onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});
</script>

<style scoped lang="scss">
.c-add-client {
  margin: var(--default-content-margin);
  margin-bottom: 7rem;
}

.c-border-div {
  padding: 0.5rem;
  border-bottom: var(--footer-border);
}

@media only screen and (min-width: 999px) {
  .c-border-div {
    padding: 0.5rem;
    border-bottom: none;
    border-right: var(--footer-border);
    &:last-child {
      border-right: none;
    }
  }
}
</style>
