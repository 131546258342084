<template>
  <EmployeeToolbar
    @onExport="exportCSV"
    :showSidebar="showSidebar"
    @onToggleFilterSidebar="onToggleFilterSidebar"
    @employeesListsRefresh="onRefreshList"
    v-model:filters="filtersComputed"
  />
  <div class="c-employees">
    <div class="flex">
      <div class="flex-auto c-transition">
        <div class="c-card">
          <EmployeeList
            :employees="employees"
            :clients="clients"
            :loading="loading"
            :page="page"
            :pageSize="pageSize"
            :totalHits="totalHits"
            @update:page="onUpdatePage"
            ref="employeeListRef"
            @update:pageSize="onUpdatePageSize"
          />
        </div>
      </div>
      <div class="pb-3" :class="showSidebar ? 'pl-5' : ''">
        <EmployeesFilterSidebar :showSidebar="showSidebar" v-model:filters="filtersComputed" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from "vue";

import { Employee } from "@/repositories/employee/model/Employee";
import { useEmployee } from "@/repositories/employee/EmployeeService";
import EmployeesFilterSidebar from "../components/EmployeesFilterSidebar.vue";

import EmployeeToolbar from "./EmployeeToolbar.vue";
import EmployeeList from "../components/EmployeeList.vue";
import { EmployeeSearchFilters } from "@/repositories/employee/model/EmployeeSearchFilters";
import { Client } from "@/repositories/employee/client/model/Client";
const { getEmployees, getClients } = useEmployee();

const employees = ref<Employee[]>([]);
const employeeListRef = ref();
const showSidebar = ref(true);
const loading = ref(false);
const pageSize = ref<number>(50);
const page = ref(1);
const totalHits = ref(0);
const clients = ref<Client[]>([]);
const filters = ref<EmployeeSearchFilters>(new EmployeeSearchFilters());

const exportCSV = () => {
  employeeListRef.value.exportCSV();
};

const filtersComputed = computed<EmployeeSearchFilters>({
  get: () => filters.value,
  set: async (value) => {
    await onFilterUpdate(value);
  },
});

const onFilterUpdate = async (value: EmployeeSearchFilters) => {
  filters.value = value;
  loading.value = true;
  await loadEmployees();
};

const onUpdatePageSize = async (value: number) => {
  pageSize.value = value;
  loading.value = true;
  await loadEmployees();
};

const loadEmployees = async () => {
  try {
    employees.value = await getEmployees();
    clients.value = await getClients();
    totalHits.value = employees.value.length;
  } finally {
    loading.value = false;
  }
};
const onRefreshList = async () => {
  loading.value = true;
  await loadEmployees();
};

const onToggleFilterSidebar = () => {
  showSidebar.value = !showSidebar.value;
};

const onUpdatePage = (value: number) => {
  page.value = value;
  loading.value = true;
  loadEmployees();
};

onMounted(async () => {
  loading.value = true;
  await loadEmployees();
});
</script>

<style scoped lang="scss">
.c-employees {
  margin: var(--default-content-margin);
}
</style>
